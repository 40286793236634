import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {ScrollTop} from './components/scroll-top'
import {reInitMenu} from '../helpers'
import {PageDataProvider} from './core'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer/user/FooterWrapper'
import {ThemeModeProvider, ActivityDrawer} from '../partials'
import {Sidebar} from './components/sidebar'
import {ToolbarWrapper} from './components/toolbar'
import {HeaderWrapper} from './components/header'

const UserLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <>
      <PageDataProvider>
        <ThemeModeProvider>
          <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
            <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
              <HeaderWrapper />
              <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
                <Sidebar />
                <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                  <div className='d-flex flex-column flex-column-fluid'>
                    <ToolbarWrapper />
                    <Content>
                      <Outlet />
                    </Content>
                  </div>
                  {/* <FooterWrapper /> */}
                </div>
              </div>
            </div>
          </div>

          {/* begin:: Drawers */}
          <ActivityDrawer />
          {/* <RightToolbar /> */}
          {/* <DrawerMessenger /> */}
          {/* end:: Drawers */}

          {/* begin:: Modals */}
          {/* <InviteUsers />
        <UpgradePlan /> */}
          {/* end:: Modals */}
          <ScrollTop />
        </ThemeModeProvider>
      </PageDataProvider>
      {/* <PageDataProvider>
        <HeaderWrapper />
        <Content>
          <Outlet />
        </Content>
        <ScrollTop />
      </PageDataProvider> */}
    </>
  )
}

export {UserLayout}
